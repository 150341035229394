/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
:root {
  --scrollbar-background: hsl(0, 0%, 16%);
  --scrollbar-thumb: hsl(0, 0%, 34%);
  --scrollbar-thumb-hover: hsl(0, 0%, 39%);
  --scrollbar-thumb-active: hsl(0, 0%, 52%);
  --scrollbar-button-hover: hsl(0, 0%, 24%);
  --scrollbar-button-active: hsl(0, 0%, 37%);
}
::-webkit-scrollbar {
  background: var(--scrollbar-background);
  width: 17px;
}
::-webkit-scrollbar-button {
  height: 17px;
  width: 17px;
  background-position: center;
  background-repeat: no-repeat;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="8" height="8" viewBox="0 0 1024 1024" style="fill:lightgray;"> <title/> <path d="M977.5 766.5l-465.5-465-465.5 465-45-45 510.5-511 510.5 511z"/> </svg>');
}
::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="8" height="8" viewBox="0 0 1024 1024" style="fill:lightgray;"> <title/> <path d="M969.5 233.5l45 45-502.5 502.5-502.5-502.5 45-45 457.5 457.5z"/> </svg>');
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: none;
  border-left: 1px solid var(--scrollbar-background);
  border-right: 1px solid var(--scrollbar-background);
  border-radius: 0;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}
::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-thumb-active);
}
::-webkit-scrollbar-button:hover {
  background-color: var(--scrollbar-button-hover);
}
::-webkit-scrollbar-button:active {
  background-color: var(--scrollbar-button-active);
}
